import React from "react";

import "./Footer.css";
import { useTranslation } from "react-i18next";
import { ImStack } from "react-icons/im";
import {
  FaAddressCard,
  FaPhone,
  FaHome,
  FaMapMarkedAlt,
  FaClipboardList,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
function Footer() {
  const { t } = useTranslation();
  return (
    <div className="main-footer">
      <div className="container-full">
        <div className="row">
          {/* Column1 */}
      <div className="col-md-12 col-lg-12">
        <table style={{height: '825px', width: '96.759%'}} width={768}>
          <tbody>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                <span style={{color: '#ffffff', fontSize: '14pt'}}><strong>สอบถามข้อมูลเพิ่มเติม</strong></span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#ffffff', fontSize: '14pt'}}><strong>บริการช่วยเหลือ</strong></span></td>
            </tr>
            <tr style={{height: '23px'}}>
              <td style={{height: '23px', width: '28.323%'}}>
                <span style={{color: '#FFEB3B'}}><strong>บริษัท ออลล์โซลูชั่นส์เทค จำกัด (สำนักงานใหญ่)</strong></span></td>
              <td style={{width: '21.2386%', height: '23px'}}>
                <span style={{color: '#FFEB3B'}}><strong>รับความช่วยเหลือทางโทรศัพท์ หรือนัดหมายการบริการ</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                <strong>ที่อยู่ :</strong> 47/316 ถ.ป๊อปปูล่า ต.บ้านใหม่ อ.ปากเกร็ด จ.นนทบุรี 11120</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong><span style={{color: '#ff0000'}}>โทร : 02-0123795, 065-9693552&nbsp; (ตลอด 24 ชม.)</span></strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                โทร : 02-0123795</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                Line ID : <span style={{color: '#FFEB3B'}}><strong><span style={{fontSize: '14pt'}}>ast2020</span></strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                <span style={{color: '#FFEB3B'}}><strong>บริษัท ออลล์โซลูชั่นส์เทค จำกัด (สาขา นครราชสีมา)</strong></span></td>
              <td rowSpan={7} style={{width: '21.2386%', height: '168px'}}>
                <img alt="" className="wp-image-11120 alignleft" height={197} src="https://allfirealarm.com/wp-content/uploads/2024/05/1790126.jpg" width={197} /></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                <strong>ที่อยู่ : </strong>เลขที่ 899/33 หมู่ที่ 7 ต.ปรุใหญ่ อ.เมืองนครราชสีมา จ.นครราชสีมา 30000</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{height: '24px', width: '28.323%'}}>
                <strong>มือถือ</strong> : 065-969-3552</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#ffffff'}}><strong>ฝ่ายขายและศูนย์ประสานงาน</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา ปากช่อง</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> 110 ม.22 ต.จันทึก อ.ปากช่อง จ.นครรราชสีมา 30130</span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> 110 M. 22 Ban Bo Nam Thip, T.Chan Tuk Sub, A.Pak Chong</span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#ffffff'}}><strong>E-Mail : ast.allsolutionstech@gmail.com</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Nakhon Ratchasima 30130</span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#ffffff', fontSize: '14pt'}}><strong>Facebook : <a className="facebook" href="https://www.facebook.com/ast.allsolutionstech/" rel="noopener" target="_blank">Ast.allsolutionstech</a></strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Call Center : </strong>065-969-3552</span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <hr />
              </td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#ffffff'}}><strong>&nbsp;</strong></span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#ffffff'}}><strong>&nbsp;</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา ขอนแก่น</strong></span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา สงขลา</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>44/158 ม.11 ต.บ้านเป็ด อ.เมือง จ.ขอนแก่น 40000</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>2 ถ.ปุณกัณฑ์ ซอย 6 ต.คอหงส์ อ.หาดใหญ่ จ.สงขลา 90110</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>44/158 M. 11, T.Ban Pet Sub-district, A.Mueang , Khon Kaen 40000</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>2 Punkan Road, Soi 6, Kho Hong Subdistrict, Hat Yai District, Songkhla 90110</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>Call Center</strong> : 065-969-3552</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>Call Center : </strong>065-969-3552</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา อุบลราชธานี</strong></span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา ภูเก็ต</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>888/33 หมู่ 10 ต.ขามใหญ่ อ.เมือง จ.อุบลราชธานี 34000</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>17/92 หมู่ 2 ต.รัษฏา อ.เมือง จ.ภูเก็ต 83000</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>888/33 M.10, T.Kham Yai Subdistrict, A.Mueang ,</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>17/92 M. 2, Ratsada Subdistrict, Mueang District, Phuket 83000</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Ubon Ratchathani 34000</span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Call Center : </strong>065-969-3552</span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>Call Center</strong> : 065-969-3552</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                &nbsp;</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา กาญจนบุรี</strong></span></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>สาขา เชียงใหม่</strong></span></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>185 หมู่ที่ 1 ต.เลาขวัญ อ.เลาขวัญ จ.กาญจนบุรี 71210</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}><strong>ที่อยู่ :</strong> </span>63/1 ม.8 ต.ต้นเปา อ.สันกำแพง จ.เชียงใหม่ 50130</td>
            </tr>
            <tr style={{height: '48px'}}>
              <td style={{width: '28.323%', height: '48px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>185 M. 1, Lao Khwan Subdistrict, Lao Khwan District,</td>
              <td style={{width: '21.2386%', height: '48px'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address :</strong> </span>63/1 M. 8, Ton Pao Subdistrict, San Kamphaeng District, Chiang Mai 50130</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <span style={{color: '#FFEB3B'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>Kanchanaburi 71210</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>Call Center : </strong>065-969-3552</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>Call Center</strong> : 065-969-3552</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                &nbsp;</td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '10px'}}>
                <strong>&nbsp;</strong></td>
              <td style={{width: '21.2386%', height: '10px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong><span style={{color: '#FFEB3B'}}>สาขา ชลบุรี</span></strong></td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr style={{height: '24px'}}>
              <td style={{width: '28.323%', height: '24px'}}>
                <strong>ที่อยู่ :</strong> 3/13 ม.7 ซอยเขาน้อย ต.บ้านสวน อ.เมือง จ.ชลบุรี 20000</td>
              <td style={{width: '21.2386%', height: '24px'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr>
              <td style={{width: '28.323%'}}>
                <span style={{color: '#FFEB3B'}}><strong>Address : </strong>3/13 M. 7, Soi Khao Noi, Ban Suan Subdistrict, Mueang District, Chonburi Province 20000</span></td>
              <td style={{width: '21.2386%'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr>
              <td style={{width: '28.323%'}}>
                <strong>Call Center</strong> : 065-969-3552</td>
              <td style={{width: '21.2386%'}}>
                <strong>&nbsp;</strong></td>
            </tr>
            <tr>
              <td style={{width: '28.323%'}}>
                <strong>&nbsp;</strong></td>
              <td style={{width: '21.2386%'}}>
                <strong>&nbsp;</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
          {/* Column1 */}
      <div className="row">
        <p className="col-sm">
          &copy;{new Date().getFullYear()} THICC MEMES | All rights reserved |
          Terms Of Service | Privacy
        </p>
      </div>
    </div>
  </div>
  );
}

export default Footer;
