import "./css/ContactUs.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { FcNext } from "react-icons/fc";
import axios from "axios";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function ContactUs() {
  const Data = {
    username: "",
    email: "",
    subject: "",
    content: "",
  };

  const [formdata, setFormdata] = React.useState(Data);

  const Chagform = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  console.log("ddd", formdata);

  const notify = () =>
    toast.success("ส่งข้อความ สำเร็จ", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {
    await axios.post("http://astsecurity.in.th/send-contact.php", {
      name: formdata.username,
      phone: formdata.phone,
      subject: formdata.subject,
      email: formdata.email,
      content: formdata.content,
      
    });
    console.log("ทำการส่งข้อความเรียบร้อย");
  };

  const { t } = useTranslation();

  return (
    <div>

      <h1
        style={{ backgroundImage: "url(/images/bg-black.jpg)" }}
        className="h1-col-title"
      >
        {t("nav.5")}
      </h1>
      <h5 className="h1-col-title">
        {t("nav.8")}
        <FcNext size={24} />
        <Link to="/Homepage">{t("nav.12")}</Link>
        <FcNext size={24} />
        {t("nav.5")}
      </h5>
      <div className="contact-container">
        <div className="tact-column">
          {/* Column1 */}
          <div className="column-item">
            <div className="ffff-container">
              <p className="pp-form"> {t("footer.11")}</p>
              <hr /> <br />
              <img
                className="col-us-img-2"
                src="/images/icons.png"
                alt="Logohome"
              ></img>
              <br />
              <table className="contentpaneopen">
                  <tbody><tr>
                      <td valign="top" colSpan={2}>
                        <p>
                          <span style={{fontSize: '20px', lineHeight: '1.7em'}}><span style={{color: '#0000ff'}}><strong>สอบถามข้อมูลเพิ่มเติมได้ที่ ...</strong></span></span></p>
                        <p>
                          <strong><span style={{fontFamily: '"times new roman", times, serif', color: 'rgb(0, 0, 0)', fontSize: '18px', textAlign: 'left'}}>&nbsp;</span></strong><strong><span style={{fontFamily: '"times new roman", times, serif', color: 'rgb(0, 0, 0)', fontSize: '18px'}}>&nbsp;</span></strong></p>
                        <p>
                          <strong><span style={{fontFamily: '"times new roman", times, serif', color: 'rgb(0, 0, 0)', fontSize: '18px'}}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; บริษัท ออลล์โซลูชั่นส์เทค จำกัด (สำนักงานใหญ่)</span></strong></p>
                        <p style={{marginLeft: '40px'}}>
                          <span style={{fontSize: '20px'}}><strong style={{fontSize: '16px'}}><span style={{lineHeight: '1.7em'}}>&nbsp; &nbsp;มือถือสายด่วน&nbsp;</span>065-969-3552<span style={{lineHeight: '1.7em'}}>&nbsp;</span></strong>Tel./Fax. : 02-012-3795</span></p>
                        <p style={{marginLeft: '40px'}}>
                          <span style={{fontSize: '20px'}}><strong style={{fontSize: '16px'}}>&nbsp; &nbsp; Mobile; (+66)&nbsp;65-969-3552&nbsp;</strong>Tel./Fax. : (+66)2-012-3795</span></p>
                        <div style={{marginLeft: '40px'}}>
                          <span style={{fontSize: '20px'}}><span style={{fontFamily: '"times new roman", times, serif'}}><strong>&nbsp; &nbsp; E-mail :&nbsp;</strong><span style={{color: 'rgb(0, 128, 0)'}}>ast.allsolutionstech@gmail.com</span></span></span></div>
                        <div style={{marginLeft: '40px'}}>
                          <span style={{fontSize: '20px', lineHeight: '1.7em'}}><span style={{color: 'rgb(0, 128, 0)', fontFamily: '"times new roman", times, serif'}}><strong>&nbsp; &nbsp; Line : </strong>ast2020&nbsp;</span></span></div>
                        <div style={{marginLeft: '40px'}}>
                          &nbsp;</div>
                        <div style={{textAlign: 'center'}}>
                          <div style={{textAlign: 'center', lineHeight: '1.7em'}}>
                            <a href="https://line.me/ti/p/axG09qVQvh"><img alt="" src="https://www.allsolutionstech.com/images/stories/1717667617812.jpg" /></a></div>
                          <div style={{textAlign: 'left', lineHeight: '1.7em'}}>
                            &nbsp;</div>
                          <div style={{textAlign: 'left', lineHeight: '1.7em'}}>
                            <strong><span style={{color: 'rgb(0, 0, 0)', fontFamily: '"times new roman", times, serif', fontSize: '16px', textAlign: 'center'}}>สาขา นนทบุรี&nbsp;</span></strong></div>
                          <div style={{textAlign: 'left'}}></div>
                          <div style={{textAlign: 'left', marginLeft: '40px', lineHeight: '1.7em'}}>
                            <span style={{fontSize: '18px', lineHeight: '1.7em'}}><span style={{color: '#000000'}}>เลขที่ 47/316 ถ.ป๊อปปูล่า ต.บ้านใหม่ อ.ปากเกร็ด จ.นนทบุรี 11120</span></span></div>
                          <div style={{textAlign: 'left', marginLeft: '40px'}}>
                            <span style={{fontSize: '18px'}}><span style={{textAlign: 'center'}}>47/316 Popular Rd., Ban Mai Subdistrict, Pak Kret District, Nonthaburi 11120</span></span></div>
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left'}}>
                            &nbsp;</div>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left'}}>
                            <strong><span style={{fontSize: '16px'}}><span style={{color: '#000000'}}><span style={{fontFamily: '"times new roman", times, serif'}}>สาขา นครราชสีมา</span></span></span></strong></div>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left'}}>
                            &nbsp;</div>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left', marginLeft: '40px'}}>
                            <span style={{fontSize: '16px'}}><span style={{color: '#000000'}}>เลขที่&nbsp;</span> <strong><span style={{lineHeight: '1.7em'}}>899/33 หมู่ที่ 7 ต.ปรุใหญ่ อ.เมืองนครราชสีมา จ.นครราชสีมา 30000 </span></strong></span></div>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left'}}>
                            &nbsp;</div>
                          <div style={{fontSize: '11px', color: 'rgb(64, 60, 58)', fontFamily: 'Tahoma, Arial, Helvetica, sans-serif', textAlign: 'left', marginLeft: '40px'}}>
                            <span style={{fontSize: '16px'}}><strong>899/33 M.7 , T.Pru Yai, A.Mueng, NakhonRatchasima,Thailand 30000 </strong></span></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
          {/* Column2 */}

          <div className="column-item">
            {" "}
            <div className="ff-container">
              <p className="pp-form"> {t("InputForm.1")} </p>
              <div className="container">
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                  <div className="box-heading">
                    <h1>{t("InputForm.2")}</h1>
                  </div>
                  <div className=" box-feedback-form">
                    <p className="grey">{t("InputForm.3")}</p>
                    {/* input */}
                    <label className="check-label">{t("InputForm.5")}</label>
                    <div className="inputstyle">
                      <input
                        type="text"
                        name="username"
                        placeholder=" ชื่อ-นามสกุล..."
                        required
                        onChange={Chagform}
                        value={formdata.username}
                      />
                    </div>
                     {/* input */}
                     <label className="check-label">{t("InputForm.11")}</label>
                    <div className="inputstyle">
                      <input
                        type="text"
                        name="phone"
                        placeholder=" เบอร์โทรศัพท์..."
                        required
                        onChange={Chagform}
                        value={formdata.phone}
                      />
                    </div>
                    {/* input */}
                    <label className="check-label">{t("InputForm.6")}</label>
                    <div className="inputstyle">
                      <input
                        type="text"
                        name="email"
                        placeholder=" ที่อยู่อีเมล..."
                        required
                        onChange={Chagform}
                        value={formdata.email}
                      />
                    </div>
                    {/* input */}
                    <label className="check-label">{t("InputForm.7")}</label>
                    <div className="inputstyle">
                      <input
                        type="text"
                        name="subject"
                        placeholder="หัวข้อเรื่อง..."
                        onChange={Chagform}
                        value={formdata.subject}
                      />
                    </div>
                    {/* input */}{" "}
                    <label className="check-label">{t("InputForm.8")}</label>
                    <div className="inputstyle">
                      <textarea
                        type="text"
                        name="content"
                        placeholder=" ระบุรายละเอียด..."
                        onChange={Chagform}
                        value={formdata.content}
                      ></textarea>
                    </div>
                    <div className="btnstyle">
                      <button onClick={notify}>{t("InputForm.4")}</button>
                      <ToastContainer />
                    </div>
                    <div className="btnstyle">
                      <p className="guide-reset"> {t("InputForm.10")}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
